import { defineStore } from 'pinia';

export const useIntercomStore = defineStore('iotIntercom', {
    state: () => ({
        settings: {},
        user: {},
    }),
    getters: {
        enabled: state => {
            return state.settings?.enabled;
        },
    },
    actions: {
        setSettings(data) {
            this.settings = data;
        },
        setUser(data) {
            this.user = data;
        },
    },
});
