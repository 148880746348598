export default {
    user_reset_password: {
        request: ['POST', '/security/users/reset-password'],
        bulkRequest: ['POST', '/security/users/reset-password/bulk'],
        check: ['GET', '/security/users/reset-password/check'],
        confirm: ['POST', '/security/users/reset-password/confirm'],
    },

    user_set_password: {
        check: ['GET', '/security/users/set-password/check'],
        confirm: ['POST', '/security/users/set-password/confirm'],
    },

    user_forgot_password: {
        check: ['GET', '/security/users/forgot-password/check'],
        confirm: ['POST', '/security/users/forgot-password/confirm'],
    },

    user_email: {
        change: ['PUT', '/security/users/{id}/change-email'],
        checkById: ['GET', '/security/users/{id}/change-email/check'],
        checkByToken: ['GET', '/security/users/change-email/check'],
        confirm: ['PUT', '/security/users/change-email/confirm/'],
    },

    user_mobile_settings: {
        getByEmail: ['GET', '/security/users/{email}/mobile-settings'],
    },

    user_current: {
        get: ['GET', '/security/users/current'],
    },
};
