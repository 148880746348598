import IotDataProvider from '@/api/iot';
import Intercom, { show } from '@intercom/messenger-js-sdk';
import { onMounted, computed, watch } from 'vue';
import { useAlertStore } from '@/stores/alert';
import { useStore } from 'vuex';
import { useIntercomStore } from '@/stores/iot/intercom';
import getEnvVariable from '@/utils/getEnvVariable';

const INTERCOM_APP_ID = getEnvVariable('VUE_APP_INTERCOM_APP_ID');

export const useIntercom = () => {
    const { notifyError } = useAlertStore();
    const store = useStore();
    const intercomStore = useIntercomStore();

    const community = computed(() => store.getters['auth/community']);
    const supportChatVisible = computed(() => intercomStore.enabled);

    const initialize = async () => {
        try {
            const settingsResponse = await fetchSettings();
            await fetchUser();
            if (settingsResponse.enabled) {
                initializeIntercom();
            }
        } catch (error) {
            notifyError(error);
        }
    };

    const fetchSettings = async () => {
        const settingsResponse = await IotDataProvider.adapter.getSettings('intercom');
        intercomStore.setSettings(settingsResponse);
        return settingsResponse;
    };

    const fetchUser = async () => {
        const userResponse = await IotDataProvider.adapter.get('user_current');
        intercomStore.setUser(userResponse);
    };

    const initializeIntercom = () => {
        const user = intercomStore.user;
        Intercom({
            user_id: user?.id,
            name: `${user?.firstName} ${user?.lastName}`,
            email: user?.email,
            hide_default_launcher: true,
            app_id: INTERCOM_APP_ID,
        });
    };

    const displayIntercom = () => {
        show();
    };

    onMounted(() => {
        if (community.value) {
            initialize();
        }
    });

    watch(
        () => community.value,
        (newValue, oldValue) => {
            if (newValue?.id && newValue?.id !== oldValue?.id) {
                initialize();
            }
        }
    );

    return {
        fetchSettings,
        supportChatVisible,
        displayIntercom,
    };
};
