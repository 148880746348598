<template>
    <QxtDropdownMenu ref="dropdownMenu">
        <template #activator>
            <QxtAvatar
                :name="fullName"
                size="md"
                @click="dropdownMenu?.toggleOpen"
            />
        </template>
        <template #menu>
            <QxtMenu @click="dropdownMenu?.toggleOpen">
                <QxtMenuDropdownOption icon="user">
                    View Profile
                </QxtMenuDropdownOption>
                <QxtMenuDropdownOption
                    icon="log-out"
                    @click="signOut"
                >
                    Logout
                </QxtMenuDropdownOption>
            </QxtMenu>
        </template>
    </QxtDropdownMenu>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { signOut } from 'aws-amplify/auth';
import { QxtAvatar, QxtDropdownMenu, QxtMenu, QxtMenuDropdownOption } from '@asmartment/qui-base';

const dropdownMenu = ref(null);
const store = useStore();
const profile = computed(() => store.getters['auth/profile']);
const fullName = computed(() => `${profile.value.firstName} ${profile.value.lastName}`);
</script>
