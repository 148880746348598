import { computed, ref } from 'vue';
import { isEmpty, isEqual, omit, pick } from 'lodash-es';
import store from '@/store';
import { useAlertStore } from '@/stores/alert';
import AuthDataProvider from '@/api/auth';
import { PROFILE_TYPES } from '@/components/permission_service/constants';

const getRefreshedProfile = (newProfile, oldProfile = {}, keepCustomer) => {
    // TODO: update auth/profile store and refactor logic of the selected profile
    // on init `isQuext` is always false as soon as no active profile set yet.

    const exceptKeys = ['id', 'name', 'fullName', 'customerDeletedAt', 'customerId', 'customerName'];

    const newProfileData = omit(newProfile, exceptKeys);
    const oldProfileData = omit(oldProfile, exceptKeys);

    if (isEqual(newProfileData, oldProfileData)) {
        return null;
    }

    const preservedProperties = pick(oldProfile, exceptKeys);

    return keepCustomer
        ? {
            ...newProfileData,
            ...preservedProperties,
        }
        : newProfile;
};

// We need to refresh customer user profile info to prevent access ASAP when customer is removed
export const useProfilePolling = () => {
    const REFRESH_INTERVAL = 60000;

    const pollingIntervalId = ref(null);

    const userProfile = computed(() => store.getters['auth/profile']);
    const userProfiles = computed(() => store.getters['auth/profiles']);

    const isQuext = computed(() => store.getters['auth/isQuext']);

    const pollingRequired = computed(() => !isQuext.value);

    const { notifyError } = useAlertStore();

    const pullProfile = async () => {
        try {
            const profile = await AuthDataProvider.adapter.get('profile');
            const { customerUsers: profiles = [] } = profile;

            if (isEmpty(profiles)) {
                console.error('Your profile is disabled');
                //TODO: should a user be logged out or redirected to ProfileDisabledPage
                return;
            }

            if (!isEqual(userProfiles.value, profiles)) {
                await store.dispatch('auth/setProfiles', profiles);
            }

            const fetchedProfile = profiles.find(p => p.profileId === userProfile.value?.profileId) || profiles[0];

            const keepCustomer =
                fetchedProfile.profileId === userProfile.value?.profileId &&
                [PROFILE_TYPES.SUPER_ADMIN, PROFILE_TYPES.QUEXT].includes(fetchedProfile.type);

            const refreshedProfile = getRefreshedProfile(fetchedProfile, userProfile.value, keepCustomer);

            if (refreshedProfile) {
                await store.dispatch('auth/setProfile', refreshedProfile);
                if (!keepCustomer) {
                    await store.dispatch('auth/setCommunity', null);
                }
            }

            return profile;
        } catch (error) {
            notifyError(error);
        }
    };

    const startPolling = async () => {
        clearInterval(pollingIntervalId.value);
        await pullProfile();
        pollingIntervalId.value = setInterval(pullProfile, REFRESH_INTERVAL);
    };

    const stopPolling = () => {
        clearInterval(pollingIntervalId.value);
    };

    return {
        pollingRequired,
        startPolling,
        stopPolling,
        pullProfile,
    };
};
