export default {
    dh_dashboard_metrics: '/api/v1/dashboard/digital-human/metrics',
    dh_dashboard_preferences: '/api/v1/preference',
    sidebar: {
        getList: ['GET', '/api/v2/sidebar'],
        create: ['POST', '/api/v2/sidebar'],
        createById: ['POST', '/api/v2/sidebar/{id}'],
        updateById: ['PUT', '/api/v2/sidebar/{id}'],
        deleteById: ['DELETE', '/api/v2/sidebar/{id}'],
        updateItemById: ['PUT', '/api/v2/sidebar/item/{id}'],
        deleteItemById: ['DELETE', '/api/v2/sidebar/item/{id}'],
        patchItemById: ['PATCH', '/api/v2/sidebar/item/{id}'],
    },
    cube_api: {
        getOne: ['GET', '/api/v1/cube/{id}'],
        getList: ['GET', '/api/v1/cube'],
        getConfigs: ['GET', '/api/v2/config-files/cube'],
        getConfig: ['GET', '/api/v2/config-files/cube/{id}'],
        publishConfig: ['POST', '/api/v2/config-files/cube/draft/promote/{id}'],
        getDraftConfig: ['GET', '/api/v2/config-files/cube/draft/{id}'],
        updateConfigDraft: ['PUT', '/api/v2/config-files/cube/draft/{id}'],
        createConfigDraft: ['POST', '/api/v2/config-files/cube/draft'],
        deleteDraftConfig: ['DELETE', '/api/v2/config-files/cube/draft/{id}'],
    },
    report_entities: {
        createDraft: ['POST', '/api/v2/config-files/report/draft'],
        getAllDrafts: ['GET', '/api/v2/config-files/report/draft'],
        getDraftById: ['GET', '/api/v2/config-files/report/draft/{id}'],
        updateDraftById: ['PUT', '/api/v2/config-files/report/draft/{id}'],
        deleteDraftById: ['DELETE', '/api/v2/config-files/report/draft/{id}'],
        getCubeReports: ['GET', '/api/v2/config-files/report/cube/{cubeId}'],
        getSidebar: ['GET', 'api/v2/config-files/report/sidebar'],
        publishEntity: ['POST', '/api/v2/config-files/report/promote/{id}'],
    },
    sidebar_item: {
        createDraft: ['POST', '/api/v2/config-files/sidebar-item'],
        getAllDrafts: ['GET', '/api/v2/config-files/sidebar-item'],
        getDraftById: ['GET', '/api/v2/config-files/sidebar-item/{id}'],
        updateDraftById: ['PUT', '/api/v2/config-files/sidebar-item/{id}'],
        deleteDraftById: ['DELETE', '/api/v2/config-files/sidebar-item/{id}'],
        promoteDraft: ['POST', '/api/v2/config-files/sidebar-item/promote/{id}'],
    },
    report: {
        getList: ['GET', '/api/v2/report'],
        create: ['POST', '/api/v2/report'],
        update: ['PUT', '/api/v2/report'],
        getById: ['GET', '/api/v2/report/{id}'],
        deleteById: ['DELETE', '/api/v2/report/{id}'],
    },
    my_analytics: '/api/v1/my-analytics',
    schedule: '/api/v1/schedule',
    favorites: '/api/v1/favorites',
};
